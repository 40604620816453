import { graphql, useStaticQuery } from 'gatsby'
import { resolveLink } from '@rocketseat/gatsby-theme-docs-core/util/url'
import { SidebarItemsEdge } from '../../../types'

export function useSidebar(): Array<SidebarItemsEdge> {
  const data = useStaticQuery(graphql`
    {
      allSidebarItems {
        edges {
          node {
            label
            link
            lang
            items {
              label
              link
            }
            id
          }
        }
      }
      site {
        siteMetadata {
          basePath
        }
      }
    }
  `)

  const { basePath } = data.site.siteMetadata

  const {
    allSidebarItems: { edges },
  } = data

  if (basePath) {
    const normalizedSidebar = edges.map(
      // Dodany klucz 'lang', nasz 'hack' na filtrowanie itemków w sidebarze po języku
      ({ node: { label, link, lang, items, id } }: SidebarItemsEdge) => {
        if (Array.isArray(items)) {
          items = items.map((item) => ({
            label: item ? item.label : undefined,
            link: item ? resolveLink(item.link, basePath) : undefined,
          }))
        }

        return {
          node: {
            id,
            label,
            lang,
            items,
            link: resolveLink(link, basePath),
          },
        }
      },
    )

    return normalizedSidebar
  }

  return edges
}
