import React from 'react'
import { ReactElement } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useSidebar } from '../../../gatsby-theme-docs-core/hooks/useSidebar'
import { navigate } from 'gatsby'
import { getCookieConsentValue } from 'react-cookie-consent'

import {
  Container,
  LogoContainer,
  List,
  Heading,
  Item,
  SubItem,
} from '@rocketseat/gatsby-theme-docs/src/components/Sidebar/styles'
import { isExternalUrl } from '@rocketseat/gatsby-theme-docs/src/util/url'
import { SidebarItemsEdge, Maybe, Scalars } from '../../../../types'
import { isPL } from '../../../../util/langGrabber'
import loadable from '@loadable/component'
import Cookies from 'universal-cookie'

const Logo = loadable(() => import('../Logo'))
const ExternalLink = loadable(
  () =>
    import('@rocketseat/gatsby-theme-docs/src/components/Sidebar/ExternalLink'),
)
const InternalLink = loadable(
  () =>
    import('@rocketseat/gatsby-theme-docs/src/components/Sidebar/InternalLink'),
)
import { PL, GB } from 'country-flag-icons/react/3x2'

function ListWithSubItems({
  children,
  text,
}: {
  children: any
  text: string
}): ReactElement {
  return (
    <>
      <Heading>{text}</Heading>
      <SubItem>{children}</SubItem>
    </>
  )
}

export default function Sidebar({
  isMenuOpen,
}: {
  isMenuOpen: boolean
}): ReactElement {
  let data = useSidebar()

  const cookies = new Cookies()
  let goBackText

  // Wykrywamy język z URLa

  // Filtrujemy itemki w sidebarze po kluczu 'lang'
  if (isPL()) {
    data = data.filter(function (e: SidebarItemsEdge) {
      return e.node.lang == 'pl'
    })
    goBackText = 'Powrót do strony głównej'
  } else {
    data = data.filter(function (e: SidebarItemsEdge) {
      return e.node.lang == 'en'
    })
    goBackText = 'Back to the main page'
  }

  function goPL() {
    if (getCookieConsentValue('tiktalik-gdpr')) {
      cookies.set('lang', 'pl', { path: '/', domain: '.tiktalik.com' })
    }
    navigate('/pl/')
  }

  function goEN() {
    if (getCookieConsentValue('tiktalik-gdpr')) {
      cookies.set('lang', 'en', { path: '/', domain: '.tiktalik.com' })
    }
    navigate('/en/')
  }

  function renderLink(
    link: Maybe<Scalars['String']>,
    label: Maybe<Scalars['String']>,
  ): ReactElement {
    return isExternalUrl(link) ? (
      <ExternalLink link={link} label={label} />
    ) : (
      <InternalLink link={link} label={label} />
    )
  }

  const homeLink = isPL() ? '/pl/' : '/en'
  const homeLabel = isPL() ? 'Powrót na stronę główną' : 'Go to home page'

  const flagStyle = {
    height: '20px',
    border: '1px solid black',
    filter: 'drop-shadow(2px 2px 4px #000000)',
  }

  const flagContainerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
    paddingLeft: '50px',
    paddingRight: '50px',
    marginTop: '20px',
    gap: '10px 0px',
  }

  const goBackHeadingStyle = {
    paddingLeft: '30px',
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: '20px',
  } as const

  const goBackAStyle = {
    display: 'block',
    fontSize: '15px',
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    color: 'black',
    '&:hover': {
      color: '#906EEE',
    },
  }

  return (
    <Container isMenuOpen={isMenuOpen}>
      <LogoContainer>
        <Link to={homeLink} aria-label={homeLabel}>
          <Logo aria-hidden="true" />
        </Link>
      </LogoContainer>
      <div style={flagContainerStyle}>
        <PL title="Polski" style={flagStyle} onClick={() => goPL()} />
        <GB title="English" style={flagStyle} onClick={() => goEN()} />
      </div>
      <nav>
        <List>
          {data.map(
            ({ node: { label, link, items, id } }: SidebarItemsEdge) => {
              if (Array.isArray(items)) {
                const subitems = items.map((item) => {
                  return (
                    <Item key={item ? item.link : undefined}>
                      {renderLink(
                        item ? (item.link ? item.link : null) : null,
                        item ? (item.label ? item.label : null) : null,
                      )}
                    </Item>
                  )
                })

                return (
                  <ListWithSubItems key={id} text={label}>
                    {subitems}
                  </ListWithSubItems>
                )
              }

              return (
                <Item key={id}>{renderLink(link ? link : null, label)}</Item>
              )
            },
          )}
          <li style={goBackHeadingStyle}>
            <a style={goBackAStyle} href="https://tiktalik.com">
              {goBackText}
            </a>
          </li>
        </List>
      </nav>
    </Container>
  )
}

ListWithSubItems.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
  ]).isRequired,
  text: PropTypes.string.isRequired,
}

Sidebar.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
}
